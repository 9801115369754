<template>
  <div>
    <v-container>
      <p class="display-1 font-weight-light	text-center pa-4">{{ $route.params.blog.title }}</p>
      <p class="overline text-center">By <b>{{ $route.params.blog.author.username }}</b> | {{new Date($route.params.blog.date_created)}}</p>
      <div class="row">
        <div class="col-md-12 col-sm-12 col-xs-12" v-for="post in $route.params.blog.posts" :key="post.id">
          <v-img class="white--text align-end" height="400px" :src="post.image ? post.image : require('@/assets/img/home/slider2.jpg')"></v-img>
          <p class="display-1 mb-0 pt-5">{{post.title}}</p>
          <p class="pt-5 subtitle-1 font-weight-light">
            {{ post.content }}
          </p>
          <p class="body-1	font-weight-light pt-10"><b>{{ post.comments ?post.comments.length:0 }} COMMENTS</b></p>
          <v-list :three-line="true" :avatar="true" v-if="post.comments">
            <v-list-item-group v-model="comment" color="primary">
              <v-list-item v-for="(comment, i) in post.comments" :key="i" inactive="true">
                <v-list-item-avatar>
                  <v-img :src="comment.user? comment.user.pic: require('@/assets/img/home/slider2.jpg')"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="item.user.username"></v-list-item-title><v-rating v-model="rating" class=""
                    background-color="warning lighten-3" color="warning" dense></v-rating>
                  <v-list-item-subtitle v-html="comment.comment"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  data: () => ({
    rating: 4.5,
    item: 5,
    items: [
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
        title: 'Lorem ipsum dolor?',
        subtitle: "<span class='text--primary'>Ali Connors</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl tincidunt eget nullam non. Tincidunt arcu non sodales neque sodales ut etiam. Lectus arcu bibendum at varius vel pharetra. Morbi tristique senectus et netus et malesuada.\n" +
          "\n",
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
        title: 'Lorem ipsum dolor <span class="grey--text text--lighten-1">4</span>',
        subtitle: "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore"
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
        title: 'Lorem ipsum dolor',
        subtitle: "<span class='text--primary'>Sandra Adams</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
        title: 'Lorem ipsum dolor',
        subtitle: ""
      },
      {
        avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
        title: 'Lorem ipsum dolor',
        subtitle: "<span class='text--primary'>Britta Holt</span> &mdash; Lorem ipsum dolor sit amet, consectetur adipiscing elit"
      },
    ],
  }),
}
</script>
